<template>
  <div>
    <TableActions
      type="workflows"
      :create-condition="false"
      :delete-condition="false"
      :edit-condition="false"
      @filter-change="(filter) => trimmedFilter = filter"
      @filter-enter="handleEnter"
    >
      <template #actions>
        <TableAction
          v-if="selected.length === 0"
          prepend-icon="fas fa-plus"
          button-variant="outlined"
          :label="$t('workflows.create')"
          @click="handleCreateButton"
          show-text
        />
        <TableAction
          v-if="selected.length === 1 && isEditable(selected[0])"
          prepend-icon="fas fa-pen"
          :label="$t('workflows.edit_draft')"
          @click="configureWorkflow(selected[0])"
          show-text
        />
        <TableAction
          v-if="selected.length > 0"
          :label="$t('tables.move_to_bin', { number: selected.length })"
          @click="deleteDialog = true"
        >
          <template #prepend-icon>
            <RecycleBinIcon color="white" />
          </template>
        </TableAction>
      </template>
    </TableActions>
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginatedWorkflows.length"
      :total="totalWorkflows"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="3">
          {{ $t('workflows.name') }}
        </v-col>
        <v-col
          cols="2"
          class="justify-center"
        >
          {{ $t('workflows.status') }}
        </v-col>
        <v-col>
          {{ $t('workflows.created_at') }}
        </v-col>
        <v-col>
          {{ $t('workflows.updated_at') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedWorkflows"
            :key="item.uuid"
            class="table-row fade-in table-row-height"
          >
            <v-col
              class="h-100"
              cols="auto"
            >
              <v-checkbox
                v-model="item.selected"
                class="left-gap mt-0"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col
              cols="3"
              class="h-100"
            >
              <ItemName
                :ref="`name_${item.id}`"
                :key="item.id"
                :item="item"
                :editing-allowed="false"
                @name-click="configureWorkflow(item)"
              />
            </v-col>
            <v-col
              cols="2"
              class="h-100 justify-center"
            >
              <MaxWidthChip
                class="mb-0 mr-0"
                :text-array="[$t(`workflows.${item.status}`)]"
                small
              />
            </v-col>
            <v-col class="h-100">
              {{ formatDate(item.created_at) }}
            </v-col>
            <v-col class="h-100">
              {{ item.updated_at && formatDate(item.updated_at) || '-' }}
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$tc('workflows.archive', selected.length)"
      :message="$tc('workflows.archive_confirmation', selected.length)"
      @confirm="deleteWorkflows"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { displaySnackbarSuccess } from '@/utils/SnackbarUtils';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableAction from '@/components/common/elements/Tables/TableAction';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import SortButton from '@/components/common/elements/Tables/SortButton';
import RecycleBinIcon from '@/components/common/elements/Navigation/RecycleBinIcon';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';

import format_mixin from '@/mixins/format';

export default {
  name: 'WorkflowsView',

  mixins: [format_mixin],

  components: {
    TableActions,
    TableAction,
    ConfirmDialog,
    ItemName,
    TableWithFooter,
    MaxWidthChip,
    SortButton,
    RecycleBinIcon,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      allSelected: false,
      paginatedWorkflows: [],
      deleteDialog: false,
      totalWorkflows: 0,
      trimmedFilter: '',
      loading: false,
      sortDesc: true,
      retentionDays: 0,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    selected: {
      get() {
        if (this.paginatedWorkflows.length > 0) {
          return this.paginatedWorkflows.filter(wf => wf.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
  },

  watch: {
    sortDesc() {
      this.getWorkflows(true);
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getWorkflows(true);
    },

    currentPage(page) {
      this.allSelected = false;
      this.paginatedWorkflows.forEach(item => {
        item.selected = this.allSelected;
      });
      this.getWorkflows(true, (page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getWorkflows(true);
      }, 300
    ),
  },

  async mounted() {
    this.loading = true;
    this.getWorkflows();
    this.loading = false;
  },

  methods: {
    handleSelect() {
      this.allSelected = this.paginatedWorkflows.every(item => item.selected);
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.paginatedWorkflows.forEach(item => {
        item.selected = false;
      });
    },

    async updateWorkflow(workflow) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await WorkflowAPI.updateWorkflow(
          workflow.id,
          { active: workflow.active }
        );
        this.$store.commit('setLoadingScreen', false);
        await this.$store.commit(
          'setSuccessMessage', this.$t('workflows.updated')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getWorkflows(
      force = false,
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage)
    {
      if (force) {
        this.loading = true;
      }
      try {
        const [workflows, total] = await WorkflowAPI.getByUUID(
          null,
          this.trimmedFilter,
          this.sortDesc,
          offset,
          limit,
        );
        this.paginatedWorkflows = workflows;
        this.totalWorkflows = total;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        if (force) {
          this.loading = false;
        }
      }
    },

    toggleSelectAll() {
      this.paginatedWorkflows.forEach(item => {
        item.selected = this.allSelected;
      });
    },

    handleEnter() {
      if (this.paginatedWorkflows.length > 0) {
        this.configureWorkflow(this.paginatedWorkflows[0]);
      }
    },

    configureWorkflow(workflow) {
      this.$router.push({
        name: 'ConfigureWorkflow',
        params: { id: workflow.draft_id || workflow.id },
      });
    },

    handleCreateButton() {
      this.$router.push({
        name: 'ConfigureWorkflow',
        params: { id: 'new' },
      });
    },

    async deleteWorkflows() {
      await Promise.all(this.selected.map(async wf => {
        try {
          return await WorkflowAPI.archiveWorkflow(wf.uuid);
        } catch (error) {
          if (error.response.status === 412) {
            return
          } else {
            this.$store.commit('setSnackbar', true);
            console.log(error);
            return
          }
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getWorkflows(true);
      const lastPage = Math.max(1, Math.ceil(this.totalWorkflows / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      displaySnackbarSuccess(this.$tc('workflows.deleted_message', 2));
    },

    isEditable(wf) {
      return wf && (wf.draft_id || wf.status === 'draft');
    }
  },
}
</script>
<style lang="scss" scoped>
  .show-more-versions-button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .config-input-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 50px;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }

  .warning-box {
    background-color: rgba(var(--v-theme-primary), 0.25);
    border: 1px solid rgb(var(--v-theme-primary));
    border-radius: 5px;
    font-size: 0.8rem;
    font-style: italic;
    padding: 10px 15px;
  }
</style>
